<template>
  <!-- <div class="login"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
  <div class="login-box">
    <div class="title">找回密码</div>
    <!-- <input
      type="text"
      id="user"
      placeholder="请输入账号用户名"
      autocomplete="off"
    /> -->
    <input
      type="text"
      id="phone"
      v-model="phone"
      placeholder="请输入注册手机号"
      autocomplete="off"
    />
    <div class="codebox">
      <input
        type="text"
        id="code"
        v-model="code"
        placeholder="请输入短信验证码"
        autocomplete="off"
      />
      <div class="code" @click="getCode(0)">获取验证码</div>
    </div>
    <div class="tologin">
      <router-link to="/login" style="font-size: 14px"
        >已有账号，立即登录</router-link
      >
    </div>

    <div class="login-btn" @click="toRepwd">
      <img src="../../img/登录_slices/矩形 2 拷贝 2.png" alt="" />
      <span>下一步</span>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import $ from "jquery";
import * as util from "@/unit/network";
export default {
  name: "Login",

  data() {
    return {
      checked: false,
      phone: "",
      code: "", // 验证码
    };
  },
  mounted() {},
  methods: {
    toRepwd() {
      localStorage.setItem("phone", this.phone);
      localStorage.setItem("code", this.code);
      util.post("/checkCode", { phone: this.phone, code: this.code }, (res) => {
        console.log(res);
        this.$router.push("/login/repwd");
      });
    },
    getCode(i) {
      console.log(i);
      if (i == 0) {
        util.post("/sendcode", { phone: this.phone }, (res) => {
          console.log(res);
          if (res.code == 1) {
            // this.i = 1;
            var timer = null;
            var count = 60;
            var codeText = $(".code").text();
            if (codeText == "获取验证码") {
              $(".code").css({
                color: "#ccc",
              });
              timer = setInterval(function () {
                count--;
                $(".code").text(count + "s后获取验证码");
                if (count <= 0) {
                  this.i = 0;
                  $(".code").css({
                    color: "#4461f2",
                  });
                  clearInterval(timer);
                  $(".code").text("获取验证码");
                }
              }, 1000);
            }
          }
        });
      }
      // else {
      //   return this.$message({
      //     message: "请勿重复获取验证码",
      //     type: "warning",
      //   });
      // }
    },
    // 微信注册
    wxLogin() {
      this.$router.push("/login/wxcode");
    },
  },
  components: {
    // HelloWorld,
  },
};
</script>
<style lang="less" scoped>
.login-box {
  width: 630/96in;
  height: 738/96in;
  background: url("../../img/登录_slices/组3.png") no-repeat;
  position: fixed;
  top: 50%;
  transform: translateY(-49%);
  right: 111px;
  text-align: center;
  padding: 108/96in 90/96in 80/96in;
  .title {
    font-size: 30/96in;
    font-weight: bold;
    color: #121212;
    height: 30/96in;
    line-height: 30/96in;
    letter-spacing: 0.1em;
  }
  input {
    width: 450/96in;
    height: 58/96in;
    box-shadow: 0 12/96in 29/96in 3/96in rgba(35, 24, 21, 0.08);
    // opacity: 0.75;
    border-radius: 5/96in;
    padding-left: 54/96in;
    border: none;
    outline: none;
    font-size: 16/96in;
    font-weight: 400;
    color: #000;
    margin-bottom: 50/96in;
    &#user {
      background: rgba(253, 253, 253, 0.75)
        url("../../img/登录_slices/我的 (1).png") 19/96in no-repeat;
      margin-top: 41/96in;
    }
    &#phone {
      background: rgba(253, 253, 253, 0.75)
        url("../../img/登录_slices/手机.png") 19/96in no-repeat;
      margin-top: 41/96in;
    }
    &#code {
      background: rgba(253, 253, 253, 0.75)
        url("../../img/登录_slices/验证码.png") 19/96in no-repeat;
    }
  }
  .tologin {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 13px;
  }
  // 记住密码
  .choose {
    display: flex;
    justify-content: space-between;
    padding: 0 13/96in;
    color: #313339;
    font-size: 14/96in;
    margin-bottom: 10/96in;
    height: 18/96in;
    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      /deep/ .el-checkbox__label {
        font-size: 14/96in;
        padding-left: 20/96in;
        color: #313339;
      }
    }
    .right {
      span {
        cursor: pointer;
      }
    }
  }
  // 登录
  .login-btn {
    position: relative;
    height: 116/96in;
    margin-bottom: 3/96in;
    cursor: pointer;
    img {
      position: absolute;
      left: -29/96in;
    }
    span {
      position: absolute;
      color: #edeef5;
      font-size: 20/96in;
      left: 50%;
      top: 36/96in;
      transform: translateX(-50%);
    }
  }
  // line
  .line {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14/96in;
    color: #313339;
    height: 14/96in;
    .line1 {
      width: 150/96in;
      height: 2/96in;
      background: #c1c1c1;
    }
    .text {
      margin: 0 27/96in;
    }
  }
  .login-wx {
    margin-top: 23/96in;
    height: 46/96in;
    cursor: pointer;
  }
}
.codebox {
  position: relative;
  .code {
    position: absolute;
    right: 22/96in;
    top: 19/96in;
    color: #4461f2;
    font-size: 16/96in;
    cursor: pointer;
  }
}
</style>
